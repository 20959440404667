import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { GET_LATEST_NOTIFICATION_REQUEST, GET_READ_NOTIFICATION_REQUEST, PROFILE_DETAILS_REQUEST, STORE_DETAILS_REQUEST, STORE_DETAIL_REQUEST, UPDATE_PROFILE_DETAILS_REQUEST, UPDATE_STORE_DETAILS_REQUEST, UPDATE_VENDOR_ACCOUNT_DETAILS_REQUEST } from "./type";
import { StoreDetailSuccess, getLatestNotificationRequest, getLatestNotificationSuccess, getReadNotificationSuccess, ownStoreDetailSuccess, profileDetailRequest, profileDetailSuccess, upadteProfileDetailSuccess, upadteStoreDetailsSuccess, upadteVendorAccountDetailsSuccess } from "./action";
import { DANGER, SUCCESS } from "../../../constant/commonConstants";
import { ApiResponseMessage } from "../../../helper/tosterHelper";
import { invokeApi } from "../../../helper/axiosHelper";
import { handleApiCallException } from "../../shared/action";
import { getMemberDetailsRequest } from "../../admin/members/action";

function* notificationRead({ payload: data }) {
    try {
        const res = yield call(invokeApi, "post", "/readNotification", data);
        if (res.status) {
            yield put(getReadNotificationSuccess(res.data));
            yield put(getLatestNotificationRequest({
                hraMemberId: data.hraMemberId
            }));

        } else {
            yield put(getReadNotificationSuccess([]));
            ApiResponseMessage(res.message, DANGER);
        }
    } catch (error) {
        ApiResponseMessage(error.message, DANGER);
    }
}
function* StoreDetail() {
    try {
        const res = yield call(invokeApi, "get", "/memberStoreList");
        if (res.status) {
            yield put(StoreDetailSuccess(res.data));
        } else {
            yield put(StoreDetailSuccess([]));
            ApiResponseMessage(res.message, DANGER);
        }
    } catch (error) {
        ApiResponseMessage(error.message, DANGER);
    }
}
function* notificationReq({ payload: data }) {

    try {
        const res = yield call(invokeApi, "post", "/notificationList", data);
        if (res.status) {
            yield put(getLatestNotificationSuccess(res));
        } else {
            yield put(getLatestNotificationSuccess([]));
            ApiResponseMessage(res.message, DANGER);
        }
    } catch (error) {
        ApiResponseMessage(error.message, DANGER);
    }
}


function* updateProfileDetail({ payload: data }) {
    try {
        const res = yield call(invokeApi, "put", "/editMemberProfile", data.values);
        if (res.status) {
            yield put(upadteProfileDetailSuccess(res.data));
            ApiResponseMessage(res.message, SUCCESS);
            yield put(profileDetailRequest({ hraMemberId: data.hraMemberId }));

        } else {
            yield put(upadteProfileDetailSuccess([]));
            ApiResponseMessage(res.message, DANGER);
        }
    } catch (error) {
        ApiResponseMessage(error.message, DANGER);
    }
}

function* upadteStoreDetails({ payload: data }) {
    try {
        const res = yield call(invokeApi, "post", "/editMemberStoreDetails", data.values);
        if (res.status) {
            yield put(upadteStoreDetailsSuccess(res.data));
            ApiResponseMessage(res.message, SUCCESS);
            yield put(profileDetailRequest({ hraMemberId: data.hraMemberId }));


        } else {
            yield put(upadteStoreDetailsSuccess([]));
            ApiResponseMessage(res.message, DANGER);
        }
    } catch (error) {
        ApiResponseMessage(error.message, DANGER);
    }
}

function* profileDetail({ payload: data }) {
    try {
        const res = yield call(invokeApi, "post", "/memberProfileDetails", data);
        if (res.status) {
            yield put(profileDetailSuccess(res.data));
        } else {
            yield put(profileDetailSuccess());
            ApiResponseMessage(res.message, DANGER);
        }
    } catch (error) {
        ApiResponseMessage(error.message, DANGER);
    }
}

function* ownStoreDetail({ payload: data }) {
    try {
        const res = yield call(invokeApi, "post", "/memberStoreDetails", data);
        if (res.status) {
            yield put(ownStoreDetailSuccess(res.data));
        } else {
            yield put(ownStoreDetailSuccess([]));
            ApiResponseMessage(res.message, DANGER);
        }
    } catch (error) {
        ApiResponseMessage(error.message, DANGER);
    }
}
function* updateVendor({ payload: data }) {
    try {
        const res = yield call(invokeApi, "put", "/editMemberVendorDetails", data)
        if (res.status) {
            yield put(upadteVendorAccountDetailsSuccess(res.data));
            if (data?.isAdmin) {
                yield put(getMemberDetailsRequest({ hraMemberId: data.hraMemberId }));
            } else {
                yield put(profileDetailRequest({ hraMemberId: data.hraMemberId }));
            }
            ApiResponseMessage(res.message, SUCCESS);
        } else {
            yield put(upadteVendorAccountDetailsSuccess(res));
            ApiResponseMessage(res.message, DANGER);
        }
    } catch (error) {
        ApiResponseMessage(error.message, DANGER);
        handleApiCallException(error);
    }
}
function* watchProfileRequest() {
    yield takeEvery(STORE_DETAILS_REQUEST, StoreDetail);
    yield takeEvery(PROFILE_DETAILS_REQUEST, profileDetail);
    yield takeEvery(UPDATE_PROFILE_DETAILS_REQUEST, updateProfileDetail);
    yield takeEvery(UPDATE_STORE_DETAILS_REQUEST, upadteStoreDetails);
    yield takeEvery(GET_LATEST_NOTIFICATION_REQUEST, notificationReq);
    yield takeEvery(STORE_DETAIL_REQUEST, ownStoreDetail);
    yield takeEvery(GET_READ_NOTIFICATION_REQUEST, notificationRead);
    yield takeEvery(UPDATE_VENDOR_ACCOUNT_DETAILS_REQUEST, updateVendor);
}

function* ProfileSaga() {
    yield all([fork(watchProfileRequest)]);
}

export default ProfileSaga;
