import axios from "axios";
import {
  getValueByKey,
  removeLoggedInMemberDetails,
  removeLoggedInUserDetails,
} from "../utils/authUtils";

const invokeApi = (method, url, data, headers) => {
  const baseUrl = process.env.REACT_APP_API;
  // const baseUrl = "http://localhost:5000";

  url = baseUrl + url;
  let accessToken;

  if (window.location.pathname.split("/")[1] == "admin") {
    accessToken = getValueByKey("adminAccessToken");
  } else {
    accessToken = getValueByKey("memberAccessToken");
  }
  return axios({
    method: method,
    url: url,
    headers: {
      ...headers,
      Authorization: "Bearer " + accessToken,
    },
    data: data,
  })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      if (window.location.pathname !== "/login") {
        if (window.location.pathname.split("/")[1] === "admin") {
          window.location.href = "/admin/login";
          removeLoggedInUserDetails();
        } else {
          removeLoggedInMemberDetails();
          window.location.href = "/login";
        }
      }
    }
    return Promise.reject(error);
  }
);

export { invokeApi };
