import { Field, Formik, Form } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Button, FormGroup, Label } from "reactstrap";
import { __t } from "../../../i18n/translator";
import SimpleReactValidator from "simple-react-validator";
import Select from "react-select";
import { getRoleListRequest } from "../../../redux/admin/role/action";
import { connect } from "react-redux";
import { addUserListRequest } from "../../../redux/admin/user/action";
import { addUserInitialState } from "./CommonFunction";

function AddUser(props) {
    const {
        onModalDismiss,
        RoleListRequest,
        dispatchData,
        AddUserStatus,
        AddUserRequest,
        getRoleList,
        setIsEdit,
    } = props;
    const IsEditData = props?.data?.id;
    const [closeModal, setCloseModal] = useState(false);
    const validator = useRef(new SimpleReactValidator());
    const [UserData] = useState(addUserInitialState(props?.data));
    const handleSubmit = async (values) => {
        if (validator.current.allValid()) {
            AddUserRequest({ values, dispatchData });

            setCloseModal(true);
        } else {
            validator.current.showMessages();
        }
    };
    useEffect(() => {
        RoleListRequest();
    }, [props.modalView === true]);
    useEffect(() => {
        if (closeModal === true) {
            onModalDismiss(false);
            setIsEdit(null);
            setCloseModal(false);
        }
    }, [AddUserStatus === true]);

    return (
        <>
            <Formik initialValues={UserData} onSubmit={handleSubmit}>
                {({ isSubmitting, values, setFieldValue }) => (
                    <>
                        <Form className="form-horizontal form-login">
                            <div className="category-head">
                                <FormGroup className=" mb-3">
                                    <Field
                                        type="fullName"
                                        id="fullName"
                                        name="fullName"
                                        className="form-control"
                                        placeholder="Full Name"
                                    />
                                    <Label className="form-label" htmlFor="fullname">
                                        Full Name
                                        <span className="text-danger required">*</span>
                                    </Label>
                                    {validator.current.message(
                                        __t("validations.user.fullName"),
                                        values.fullName,
                                        "required",
                                        {
                                            messages: {
                                                required: __t("validations.user.fullName"),
                                            },
                                        }
                                    )}
                                </FormGroup>

                                <FormGroup className=" mb-3">

                                    <Field
                                        type="email"
                                        id="email"
                                        name="email"
                                        className="form-control"
                                        placeholder="Email Address"
                                    />
                                    <Label className="form-label" htmlFor="email">
                                        Email Address
                                        <span className="text-danger required">*</span>
                                    </Label>
                                    {validator.current.message(
                                        __t("validations.user.email"),
                                        values.email,
                                        "required|email",
                                        {
                                            messages: {
                                                required: __t("validations.user.email"),
                                            },
                                        }
                                    )}
                                </FormGroup>
                                <FormGroup className="mb-3">
                                    <Field
                                        type="text"
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        className="form-control"
                                        placeholder="Mobile No."
                                        maxLength={10}
                                        minLength={10}
                                    />

                                    <Label className="form-label" htmlFor="phoneNumber">
                                        Mobile No.
                                        <span className="text-danger required">*</span>
                                    </Label>
                                    {validator.current.message(
                                        "phoneNumber",
                                        values.phoneNumber,
                                        "required|matches:^[0-9]{10}$|numeric",
                                        {
                                            messages: {
                                                required: __t("validations.user.phoneNumber"),
                                                matches: __t("validations.user.matches"),
                                            },
                                        }
                                    )}
                                </FormGroup>
                                <FormGroup className=" mb-3">
                                    <Field
                                        type="Role"
                                        id="Role"
                                        name="Role"
                                        className="form-control selectbox"
                                        component={Select}
                                        menuPlacement="auto"
                                        options={getRoleList}
                                        value={getRoleList?.find(
                                            (option) => option.value === values.roleId
                                        )}
                                        onChange={(data) => {
                                            setFieldValue("roleId", data.value);
                                            if (data.value === "1") {
                                                setFieldValue("vendorAccess", true);
                                            } else {
                                                setFieldValue("vendorAccess", false);
                                            }

                                        }}

                                    />
                                    <Label className="form-label" htmlFor="Role">
                                        Role
                                        <span className="text-danger required">*</span>
                                    </Label>
                                    {validator.current.message(
                                        __t("validations.user.Role"),
                                        values.roleId,
                                        "required",
                                        {
                                            messages: {
                                                required: __t("validations.user.Role"),
                                            },
                                        }
                                    )}
                                </FormGroup>
                                <FormGroup className="mb-3 ">
                                    <Field
                                        type="checkbox"
                                        id="vendorAccess"
                                        name="vendorAccess"
                                        className="form-check-input"
                                        style={{
                                            'hight': '1rem',
                                            'width': '1rem',
                                            'marginRight': "0.4rem"
                                        }}
                                        disabled={values.roleId === "1"}
                                        // onChange={() => {
                                        //     setFieldValue("vendorAccess")
                                        // }}
                                        // onBlur={handleBlur}
                                        checked={values.vendorAccess}
                                    />
                                    <Label className="form-check-label fw-normal" htmlFor="vendorAccess">
                                        Allow this user to update the vendor account number
                                    </Label>
                                </FormGroup>
                            </div>
                            <div className="btn-box">
                                <Button
                                    color="primary"
                                    className=""
                                    title="user data"
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    {IsEditData ? "Update" : "Submit"}
                                </Button>
                            </div>
                        </Form>
                    </>
                )}
            </Formik>
        </>
    );
}
const mapStateToProps = ({ userList, roleList }) => {
    let getRoleList = null;
    if (roleList?.getRoles?.list?.length > 0) {
        getRoleList = roleList.getRoles.list.map((role) => ({
            label: role.roleName,
            value: role.id,
        }));
    }

    return {
        getRoleList,
        AddUser: userList?.addUser && userList.addUser,
        AddUserStatus: userList?.addUser?.status && userList.addUser.status,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        RoleListRequest: () => {
            dispatch(getRoleListRequest());
        },
        AddUserRequest: (data) => {
            dispatch(addUserListRequest(data));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddUser);
