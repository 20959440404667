import { Formik, Form } from "formik";
import React, { useState } from "react";
import { Button, FormGroup, Input, Label } from "reactstrap";
import { columns, fixedCol } from "./columns";

function SettingTab(props) {
  const { setColumesRequest, data, onModalDismiss } = props;
  const IsEditData = data.length > 0;
  const [selectedColumns, setSelectedColumns] = useState(
    IsEditData > 0 ? data : []
  );
  const handleSelectedColumns = (event) => {
    const { id, checked } = event.target;
    const columnData = columns.find((col) => col.field === id);
    if (checked) {
      // Add the selected column to the list if it's not already included
      if (!selectedColumns.some((col) => col.field === id)) {
        setSelectedColumns((prevSelected) => [...prevSelected, columnData]);
      }
    } else {
      // Remove the deselected column from the list
      setSelectedColumns((prevSelected) =>
        prevSelected.filter((col) => col.field !== id)
      );
    }
  };

  const handleSubmit = async () => {
    setColumesRequest({ columnsSelected: selectedColumns, type:2 });
    onModalDismiss();
  };
  const checkboxOptions = columns
  .filter(col => col.label) // Filter out columns that do not have a label
  .map(col => ({
    id: col.field,
    label: col.label,
  }));

  return (
    <>
      <Formik initialValues={{}} onSubmit={handleSubmit}>
        {({ isSubmitting, values, setFieldValue }) => (
          <>
            <Form className="form-horizontal column-configuration">
              <div className="checkbox-column">
                {fixedCol.map((option) => (
                  <FormGroup key={option.id}>
                    <Input type="checkbox" id={option.id} disabled checked />
                    <Label for={option.id}>{option.label}</Label>
                  </FormGroup>
                ))}
                {checkboxOptions.map((option) => (
                  <FormGroup key={option.id}>
                    <Input
                      type="checkbox"
                      id={option.id}
                      onChange={handleSelectedColumns}
                      checked={selectedColumns.some(
                        (column) => column.field === option.id
                      )}
                    />
                    <Label for={option.id}>{option.label}</Label>
                  </FormGroup>
                ))}
              </div>
              <div className="btn-box">
                <Button
                  color="primary"
                  className=""
                  title="user data"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {IsEditData ? "Update" : "Submit"}
                </Button>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}

export default SettingTab;
