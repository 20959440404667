import React, { useState } from "react";
import ViewTable from "../../../components/Common/ViewTable";
import { connect } from "react-redux";
import { useEffect } from "react";
import { getRebateListRequest } from "../../../redux/admin/rebate/action";
import { getValueByKey } from "../../../utils/authUtils";
import {
  LeftArrowSvg,
  RightArrowSvg,
  ViewSvg,
} from "../../../components/CommonSvg";
import RebateView from "./RebateView";
import CustomisedModal from "../../../components/Modal/CustomisedModal";
import { getStoreRebatesRequest } from "../../../redux/frontend/rebate/action";
import { Button, Col, Container, Row } from "reactstrap";

function RebateList({
  getRebateList,
  RebateListData,
  RebateData,
  totalRebate,
  getStoreRebatesRequest,
  totalAmount,
}) {
  const [searchData, setSearchData] = useState(null);
  const [RebateDataList, setRebateDataList] = useState();
  const [RebateTitle, setRebateTitle] = useState();
  const [selectedClass, setSelectedClass] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(10);
  const [selectedYear, setSelectedYear] = useState("");
  const [sorting, setSorting] = useState("asc");
  const [sortingCol, setSortingCol] = useState("");
  const [modalView, setModaleView] = useState(false);
  const [selectedQuater, setSelectedQuater] = useState("");
  const [cityId, setCityId] = useState(null);
  const [stateId, setStateId] = useState(null);
  const [countyId, setCountyId] = useState(null);
  const dispatchData = {
    searchText: searchData,
    rebateYear: selectedYear,
    rebateQuarter: selectedQuater,
    rebateClassId: selectedClass,
    rebateStoreId: "",
    cityName: cityId,
    stateName: stateId,
    countyName: countyId,
    pagination: {
      limit: dataPerPage,
      page: currentPage,
      orderKey: sortingCol,
      orderBy: sorting,
    },
  };

  useEffect(() => {
    if (selectedYear) {
      setRebateDataList(RebateListData);
    } else {
      setRebateDataList([]);
    }
  }, [selectedYear, RebateListData, searchData]);

  useEffect(() => {
    if (selectedYear) {
      getRebateList(dispatchData);
    }
  }, [
    selectedQuater,
    sortingCol,
    sorting,
    currentPage,
    dataPerPage,
    selectedClass,
    selectedYear,
    searchData,
    cityId,
    stateId,
    countyId,
  ]);
  useEffect(() => {
    if (selectedClass && selectedYear) {
      getRebateList(dispatchData);
    }
  }, []);
  useEffect(() => {
    setCurrentPage(1);
  }, [selectedYear]);
  const columns = [
    {
      dataField: "storeName",
      text: "Store",
      sort: true,
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        setSorting(order);
        setSortingCol(dataField);
      },
    },
    // {
    //   dataField: "records.year",
    //   text: "Year",

    // },
    {
      dataField: "amount",
      text: "Amount",
      classes: "text-end rebate_amount",
      sort: true,
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        setSorting(order);
        setSortingCol(dataField);
      },

      formatter: (cell, row) => {
        return <>${row.totalAmount}</>;
      },
    },
    // {
    //   dataField: "storeName",
    //   text: "",
    //   sort: false,
    //   classes: "text-center",
    //   formatter: (cell, row) => {
    //     return (
    //       <div className="action_col d-flex align-items-center justify-content-center">
    //         <a
    //           className="view_btn_icon mx-2"
    //           title="View inquiry"
    //           onClick={() => {
    //             setModaleView(true);
    //             setRebateTitle(row.storeName);
    //             getStoreRebatesRequest({
    //               rebateClassId: selectedClass,
    //               rebateQuarter: selectedQuater,
    //               rebateYear: selectedYear,
    //               storeId: row.storeId,
    //               zohoToken: getValueByKey("zohoToken")
    //                 ? getValueByKey("zohoToken")
    //                 : "",
    //               zohoTokenTime: getValueByKey("zohoTokenTime")
    //                 ? getValueByKey("zohoTokenTime")
    //                 : "",
    //             });
    //           }}
    //         >
    //           <ViewSvg />
    //         </a>
    //       </div>
    //     );
    //   },
    // },
  ];
  return (
    <>
       {/* <h1 className="text-center mt-5 p-5 mb-5">Coming soon</h1> */}
      <ViewTable
        data={RebateDataList?.length > 0 ? RebateDataList : []}
        columns={columns}
        filterType={"RebateFilter"}
        selectedClass={selectedClass}
        setSelectedClass={setSelectedClass}
        Header={true}
        selectedYear={selectedYear}
        setSelectedYear={setSelectedYear}
        selectedQuater={selectedQuater}
        searchData={searchData}
        setSearchData={setSearchData}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        setDataPerPage={setDataPerPage}
        pagination={true}
        setSelectedQuater={setSelectedQuater}
        dataPerPage={dataPerPage}
        totalRecords={RebateDataList?.length > 0 && totalRebate}
        updateData={() =>
          getRebateListRequest({
            rebateClassId: "",
            rebateQuarter: "",
            rebateYear: "",
          })
        }
        rowClassName={"rebate_data cursor-pointer"}
        rowEvents={(e, row, rowIndex) => {
          setModaleView(true);
          setRebateTitle(row.storeName);
          getStoreRebatesRequest({
            rebateClassId: selectedClass,
            rebateQuarter: selectedQuater,
            rebateYear: selectedYear,
            storeId: row.storeId,
            zohoToken: getValueByKey("zohoToken")
              ? getValueByKey("zohoToken")
              : "",
            zohoTokenTime: getValueByKey("zohoTokenTime")
              ? getValueByKey("zohoTokenTime")
              : "",
          });
        }}
        cityId={cityId}
        setCityId={setCityId}
        stateId={stateId}
        setStateId={setStateId}
        countyId={countyId}
        setCountyId={setCountyId}
        hedingName="Rebate Management"
        //   searchBar={false}
      />

      {modalView && (
        <CustomisedModal
          modalView={modalView}
          customClass={
            "category-modal rebate_history  rebate-detail-modal modal-xl "
          }
          modalName={"Rebate Details : " + RebateTitle ? RebateTitle : ""}
          onModalDismiss={() => {
            setModaleView(false);
          }}
        >
          <RebateView
            RebateData={
              RebateData[0]?.classes?.length > 0 ? RebateData[0].classes : []
            }
            responsiveClass="rebate_history"
            totalAmount={RebateData[0]?.QuarterWiseClassesTotal}

            // data={data.classes?.length > 0 ? data.classes : []}
            // tableId={data.storeId}
          />
        </CustomisedModal>
      )}
    </>
  );
}
const mapStateToProps = ({ rebateManagement, rebate }) => {
  let RebateListData = [];
  let totalRebate = null;
  let RebateData = [];
  let totalAmount = null;
  if (
    rebateManagement?.getRebateList?.zohoToken &&
    rebateManagement?.getClass?.zohoTokenTime
  ) {
    localStorage.setItem("zohoToken", rebateManagement.getRebateList.zohoToken);
    localStorage.setItem(
      "zohoTokenTime",
      rebateManagement.getRebateList.zohoTokenTime
    );
  }
  if (
    rebate?.getStoreRebates?.data?.zohoToken &&
    rebate?.getStoreRebates?.data?.zohoTokenTime
  ) {
    localStorage.setItem("zohoToken", rebate?.getStoreRebates?.data?.zohoToken);
    localStorage.setItem(
      "zohoTokenTime",
      rebate?.getStoreRebates?.data?.zohoTokenTime
    );
  }
  // ;
  // rebateManagement?.getClass?.zohoTokenTime;
  if (
    rebateManagement?.getRebateList?.list?.length > 0 &&
    rebateManagement?.getRebateList?.totalRecords
  ) {
    RebateListData = rebateManagement.getRebateList.list;

    totalRebate = rebateManagement.getRebateList.totalRecords;
  }
  if (rebate?.getStoreRebates?.data?.RebateData?.length > 0) {
    RebateData = rebate?.getStoreRebates.data.RebateData;
  }
  if (rebate.getStoreRebates?.data?.totalsArray) {
    totalAmount = rebate.getStoreRebates?.data.totalsArray;
  }

  return {
    RebateListData: RebateListData,
    totalRebate: totalRebate,
    RebateData: RebateData,
    totalAmount: totalAmount,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getRebateList: (data) => dispatch(getRebateListRequest(data)),
    getStoreRebatesRequest: (data) => dispatch(getStoreRebatesRequest(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RebateList);
