import React, { useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Logo from "../../../assets/images/logo-light.webp";
import Dropdown from "react-bootstrap/Dropdown";
import {
  getUserName,
  getValueByKey,
  removeLoggedInUserDetails,
} from "../../../utils/authUtils";
import {
  ApiResponseMessage,
  SignOutTostrMessage,
} from "../../../helper/tosterHelper";
import {
  AchSvg,
  GuideInteractiveSvg,
  LogoutSvg,
  MemberUserGuideSvg,
  SettingSvg,
  SyncSvg,
  SyncVendor,
  UserGuideSvg,
  VendorSvg,
} from "../../CommonSvg";
import {
  RoleWiseModule,
  getInitials,
} from "../../../utils/generalUtils";
import { getLoginClear } from "../../../redux/admin/auth/login/action";
import {AdminAccess,MarketingAccess,SUCCESS,StaffAccess,} from "../../../constant/commonConstants";
import { useEffect } from "react";
import { syncVendorRequest, zohoStoreListRequest } from "../../../redux/admin/members/action";
import { commonConfirmBox } from "../../../helper/messagehelpers";
import { connect } from "react-redux";
import CustomisedModal from "../../Modal/CustomisedModal";
import { Button, FormGroup, Input, Label } from "reactstrap";
import { getDefaultPathRequest } from "../../../redux/admin/role/action";
import { getAmericaNewYorkTimeZoneDate } from "../../../helper/DataTimeHelpers";

const Header = ({ zohoStoreListRequest, setDefaultPathRequest, syncVendorRequest }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const logout = () => {
    navigate("/admin/login");
    getLoginClear();
    SignOutTostrMessage();
    removeLoggedInUserDetails();
  };
  const authName = getUserName();
  useEffect(() => {
    if (location.pathname != "/admin/promotion") {
      localStorage.removeItem("activeTab");
    }
  }, [location.pathname]);
  const [isModal, setIsModal] = useState(false);
  const [defaultPath, setDefaultPath] = useState({
    favouriteMenu: "/admin/home",
  });
  const heandalSetting = () => {
    setIsModal(!isModal);
  };
  const adminLinks = [
    RoleWiseModule(AdminAccess, StaffAccess, MarketingAccess) && {
      label: "Dashboard",
      to: "/admin/home",
    },
    RoleWiseModule(AdminAccess, StaffAccess, MarketingAccess) && {
      label: "Promotions",
      to: "/admin/promotion",
    },
    RoleWiseModule(AdminAccess, StaffAccess) && {
      label: "Members",
      to: "/admin/members",
    },
    RoleWiseModule(AdminAccess, StaffAccess) && {
      label: "Inquiry",
      to: "/admin/inquiry",
    },
    RoleWiseModule(AdminAccess) && {
      label: "ACH Management",
      to: "/admin/ach",
    },
    RoleWiseModule(AdminAccess) && {
      label: "User Management",
      to: "/admin/user",
    },
  ].filter((link) => link);

  useEffect(() => {
    if (getValueByKey("favouriteMenu")) {
      setDefaultPath({ favouriteMenu: getValueByKey("favouriteMenu") });
    }
  }, [getValueByKey("favouriteMenu")]);
  const lastSync = getValueByKey("lastSync");

  return (
    <>
      <Navbar className={location.pathname === "/" ? "dashboard-page" : ""}>
        <Container>
          <Navbar.Brand>
            <Link to="/admin/home">
              <img alt="Logo" className="logo_main" src={Logo} />
            </Link>
          </Navbar.Brand>

          <Nav className="">
            {RoleWiseModule(AdminAccess, StaffAccess, MarketingAccess) && (
              <Nav.Link as={NavLink} to="/admin/home">
                Dashboard
              </Nav.Link>
            )}
            {RoleWiseModule(AdminAccess, StaffAccess, MarketingAccess) && (
              <Nav.Link as={NavLink} to="/admin/promotion">
                Promotions
              </Nav.Link>
            )}
            {RoleWiseModule(AdminAccess, StaffAccess) && (
              <Nav.Link as={NavLink} to="/admin/members">
                Members
              </Nav.Link>
            )}
            {/* {RoleWiseModule(AdminAccess, StaffAccess) && (
              <Nav.Link as={NavLink} to="/admin/inquiry">
                Inquiry
              </Nav.Link>
            )} */}
            {/* {RoleWiseModule(AdminAccess) && ( */}
            <Nav.Link as={NavLink} to="/admin/vendor">
              Vendor Program
            </Nav.Link>
            {/* )} */}
            {/* 
            {RoleWiseModule(AdminAccess) && (
              <Nav.Link as={NavLink} to="/admin/ach">
                ACH Management
              </Nav.Link>
            )} */}
            {RoleWiseModule(AdminAccess, StaffAccess) && (
              <Nav.Link as={NavLink} to="/admin/rebate">
                Rebates
              </Nav.Link>
            )}
            {RoleWiseModule(
              AdminAccess,
              StaffAccess,
              MarketingAccess
            ) && (
                <Nav.Link as={NavLink} to="/admin/support">
                  Support Request
                </Nav.Link>
              )}

            {RoleWiseModule(AdminAccess,
              StaffAccess,
              MarketingAccess) && (
                <Nav.Link as={NavLink} to="/admin/newsletter">
                  Newsletter
                  {/* & Vendor program */}
                </Nav.Link>
              )}

            {RoleWiseModule(AdminAccess) && (
              <Nav.Link as={NavLink} to="/admin/user">
                User Management
              </Nav.Link>
            )}
            {/* {RoleWiseModule(AdminAccess) && (
              <Nav.Link as={NavLink} to="/admin/user">
                User Management
              </Nav.Link>
            )} */}
            {/* {RoleWiseModule(AdminAccess, StaffAccess) && (
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  Management
                </DropdownToggle>
                <DropdownMenu right>
                  {RoleWiseModule(AdminAccess) && (
                    <DropdownItem tag={NavLink} to="/admin/user">
                      User Management
                    </DropdownItem>
                  )}
                  {RoleWiseModule(AdminAccess) && (
                    <DropdownItem tag={NavLink} to="/admin/ach">
                      ACH Management
                    </DropdownItem>
                  )}
                  {RoleWiseModule(AdminAccess, StaffAccess) && (
                    <DropdownItem tag={NavLink} to="/admin/inquiry">
                      Inquiry
                    </DropdownItem>
                  )}
                  {RoleWiseModule(
                    AdminAccess,
                    StaffAccess,
                    MarketingAccess
                  ) && (
                    <DropdownItem tag={NavLink} to="/admin/support">
                      Support
                    </DropdownItem>
                  )}
                </DropdownMenu>
              </UncontrolledDropdown>
            )} */}
            <div className="right_header">
              <Dropdown className="profile_btn">
                <Dropdown.Toggle
                  id="dropdown-basic"
                  className="position-relative"
                >
                  <div className="profile_name">
                    <h5>{authName}</h5>
                    <div>
                      <p>
                        {RoleWiseModule(AdminAccess) && "Admin"}
                        {RoleWiseModule(StaffAccess) && "Staff"}
                        {RoleWiseModule(MarketingAccess) && "Marketing"}
                      </p>
                    </div>
                  </div>
                  <span className="user-profile">{getInitials(authName)}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={heandalSetting}>
                    <SettingSvg />
                    Setting
                  </Dropdown.Item>
                  {RoleWiseModule(AdminAccess) && (
                    <Dropdown.Item
                      // disabled={isZohoSyncEnabled}
                      onClick={async () => {
                        const response = await commonConfirmBox(
                          "Are you sure you want to start Zoho sync?"
                        );
                        if (response) {
                          zohoStoreListRequest();
                          ApiResponseMessage(
                            "Zoho Sync started successfully",
                            SUCCESS
                          );
                        }
                      }}
                    >
                      <SyncSvg />
                      <span>
                        <span>Sync Zoho </span>
                        {lastSync && (
                          <small className="text-muted mt-1 sync-data">
                            <span>
                              {/* <Date_Svg /> */}
                              {getAmericaNewYorkTimeZoneDate(lastSync)}
                            </span>
                          </small>
                        )}
                      </span>
                    </Dropdown.Item>
                  )}
                  {RoleWiseModule(AdminAccess) && (
                    <Dropdown.Item
                      // disabled={isZohoSyncEnabled}
                      onClick={async () => {
                        const response = await commonConfirmBox(
                          "Are you sure you want to start Vendor sync?"
                        );
                        if (response) {
                          syncVendorRequest();
                        }
                      }}
                    >
                      <SyncVendor />
                      <span>
                        <span>Sync Vendor </span>
                      </span>
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item
                    onClick={() =>
                      window.open(
                        "https://www.hraga.com/mbr-app-adminuser-guide"
                      )
                    }
                  >
                    <UserGuideSvg />
                    <span>User Guide</span>
                  </Dropdown.Item>
                  {/* <Dropdown.Item
                    className="vendor_dropdown"
                    onClick={() =>
                      window.open("https://www.hraga.com/vendor-programs")
                    }
                  >
                    <VendorSvg />
                    <span>Vendor & Partner</span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      window.open(
                        "https://www.hraga.com/temporary-resource-center"
                      )
                    }
                  >
                    <AchSvg />
                    <span>Resource Center</span>
                  </Dropdown.Item> */}
                  <Dropdown.Item
                    onClick={() =>
                      window.open(
                        "https://www.hraga.com/_files/ugd/22f080_7ea0f21c2bee4b8697fa1f6d6a775d30.pdf"
                      )
                    }
                  >
                    <MemberUserGuideSvg />
                    <span>Member's User Guide .PDF</span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      window.open(
                        "https://www.hraga.com/member-application-user-guide"
                      )
                    }
                  >
                    <GuideInteractiveSvg />
                    <span>Member's User Guide Interactive</span>
                  </Dropdown.Item>
                  <Dropdown.Item onClick={logout}>
                    <LogoutSvg />
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Nav>
        </Container>
      </Navbar>

      <CustomisedModal
        modalView={isModal}
        customClass={"category-modal"}
        modalName={"Setting"}
        onModalDismiss={heandalSetting}
      >
        <FormGroup>
          <Input
            id="path"
            name="select"
            type="select"
            className="form-select"
            onChange={(e) => {
              setDefaultPath({ favouriteMenu: e.target.value });
            }}
            value={defaultPath.favouriteMenu}
          >
            {adminLinks.map(
              (link, index) =>
                link && (
                  <option key={index} value={link.to}>
                    {link.label}
                  </option>
                )
            )}
          </Input>
          <Label className="form-label" htmlFor="year">
            Set Your Default Page
          </Label>
        </FormGroup>
        <div className="text-center">
          <Button
            color="primary"
            onClick={() => {
              setDefaultPathRequest(defaultPath);
              heandalSetting();
            }}
          >
            Submit
          </Button>
        </div>
      </CustomisedModal>
    </>
  );
};

const mapStateToProps = ({ profileData }) => {
  let StoreDetail = null;
  return {
    StoreDetail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    zohoStoreListRequest: () => dispatch(zohoStoreListRequest()),
    syncVendorRequest: () => dispatch(syncVendorRequest()),
    setDefaultPathRequest: (data) => dispatch(getDefaultPathRequest(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
