import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getMemberListRequest,
  removeStoreGroupRequest,
} from "../../../redux/admin/members/action";
import { getDateObjectWithValueFromMomentPayload } from "../../../helper/DataTimeHelpers";
import { exportDataExportRequest } from "../../../redux/exportData/action";
import CustomisedModal from "../../../components/Modal/CustomisedModal";
import PromotionModal from "./PromotionModal";
import MemberData from "./MemberData";
import TableHeader from "../../../components/Common/TableView/TableHeader";
import ExportData from "./ExportData";
import SupportTicket from "../SupportRequest/SupportTicket";
import { SettingSvg } from "../../../components/CommonSvg";
import SettingTab from "./Settings";
import { getColumesRequest, setColumesRequest } from "../../../redux/admin/supportTickets/action";

function MemberList(props) {
  const { totalRecords, getMemberListReq, allMemberList,getColumesRequest, colList, setColumesRequest } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(25);
  const [searchData, setSearchData] = useState("");
  const [cityId, setCityId] = useState("");
  const [stateId, setStateId] = useState("");
  const [exportFilterData, setExportFilterData] = useState(false);
  const [countyId, setCountyId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isRefresh, setisRefresh] = useState(false);
  const [isPromotionDataModal, setIsPromotionDataModal] = useState(false);
  const [isExcelExport, setIsExcelExport] = useState(false);
  const [orderBy, setOrderBy] = useState("ASC");
  const [orderKey, setOrderKey] = useState("createdDate");
  const [selectedStore, setSelectedStore] = useState(null);
  const dispatchData = {
    searchText: searchData ? searchData : "",
    startDate: startDate
      ? getDateObjectWithValueFromMomentPayload(startDate)
      : "",
    endDate: endDate ? getDateObjectWithValueFromMomentPayload(endDate) : "",
    state: stateId,
    city: cityId,
    county: countyId,
    pagination: {
      limit: dataPerPage,
      page: currentPage,
      orderKey: orderKey,
      orderBy: orderBy,
    },
  };

  useEffect(() => {
    if (
      dataPerPage ||
      currentPage ||
      searchData ||
      startDate ||
      endDate ||
      stateId ||
      cityId ||
      countyId
    ) {
      getMemberListReq(dispatchData);
    }
  }, [
    dataPerPage,
    currentPage,
    searchData,
    startDate,
    endDate,
    stateId,
    cityId,
    countyId,
    isRefresh,
    orderBy,
  ]);
  useEffect(() => {
    setCurrentPage(1);

  }, [searchData]);
  const [settingView, setSettingView] = useState(false);
  const handleSettingsToggle = () => {
    setSettingView(!settingView);
  };
  const [tableRef, setTableRef] = useState(true);
  useEffect(() => {
    if (colList?.columnsSelected >= 0) {
      setTableRef(false);
    }
    getColumesRequest({id:2});
    setTableRef(true);
  }, []);
  return (
    <>
      <div className="container">
        <TableHeader
          hedingName={"Members"}
          setCurrentPage={setCurrentPage}
          exportData={true}
          setExportFilterData={setExportFilterData}
          exportFilterData={exportFilterData}
          exportDataHandel={() => {
            setIsExcelExport(!isExcelExport);
            // props.exportDataExportRequest({
            //   type: "post",
            //   url: "/memberDataExport",
            //   data: { ...dispatchData, isFilteredDataExport: exportFilterData },
            // });
          }}
          settings={
            <>
              <button
                className="creat_btn btn btn-secondary"
                onClick={handleSettingsToggle}
              >
                <SettingSvg /> Display Settings
              </button>
            </>
          }
          searchBar={"true"}
          currentPage={currentPage}
          setSearchData={setSearchData}
          setEndDate={setEndDate}
          endDate={endDate}
          startDate={startDate}
          setStartDate={setStartDate}
          updateData={() => {
            getMemberListReq({
              searchText: "",
              startDate: "",
              endDate: "",
              pagination: {
                limit: dataPerPage,
                page: currentPage,
                orderKey: "createdDate",
                orderBy: "ASC",
              },
            });
          }}
          setStateId={setStateId}
          Filter={true}
          filterType={"Members"}
          setCountyId={setCountyId}
          setCityId={setCityId}
          promotionModalButton={true}
          promotionModal={() => {
            setIsPromotionDataModal(true);
          }}
        />

        <MemberData
          allMemberList={allMemberList}
          dataPerPage={dataPerPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          setDataPerPage={setDataPerPage}
          totalRecords={totalRecords}
          removeStoreGroupRequest={props.removeStoreGroupRequest}
          dispatchData={dispatchData}
          setSelectedStore={setSelectedStore}
          orderBy={orderBy}
          columnsSelected={colList?.columnsSelected}
          onChangeOrderBy={(data) => {
            if (orderBy == "ASC") {
              setOrderBy("DESC");
              setOrderKey(data);
            } else {
              setOrderKey(data);
              setOrderBy("ASC");
            }
          }}
          isRefresh={(value) => {
            if (value == true) {
              setisRefresh(isRefresh ? false : true);
            }
          }}
        />

        <div>
          {isPromotionDataModal && (
            <CustomisedModal
              searchBar={"true"}
              setSearchData={setSearchData}
              modalView={isPromotionDataModal}
              customClass={"category-modal modal-lg store_modal"}
              modalName={"Create Store Group"}
              onModalDismiss={() => {
                setIsPromotionDataModal(false);
              }}
            >
              <PromotionModal
                onModalDismiss={() => {
                  setisRefresh(isRefresh ? false : true);
                  setIsPromotionDataModal(false);
                }}
              />
            </CustomisedModal>
          )}
          {isExcelExport && (
            <CustomisedModal
              modalView={isExcelExport}
              customClass={""}
              modalName={"Export Data"}
              onModalDismiss={() => {
                setIsExcelExport(!isExcelExport);
              }}
            >
              <ExportData
                closeModal={() => {
                  setIsExcelExport(!isExcelExport);
                }}
                dispatchData={dispatchData}
                exportFilterData={exportFilterData}
              />
            </CustomisedModal>
          )}

          {selectedStore && (
            <CustomisedModal
              modalView={selectedStore}
              scrollable="true"
              customClass={
                "category-modal admin-support-ticket support-request-modal modal-lg "
              }
              modalName={
                <div className="ach_modal_header d-flex justify-content-between w-100">
                  {selectedStore?.details
                    ? "Edit support ticket"
                    : "Create support ticket"}
                  <span>{selectedStore.hraMemberId}</span>
                </div>
              }
              onModalDismiss={() => {
                setSelectedStore(false);
              }}
            >
              <SupportTicket
                onModalDismiss={() => {
                  setSelectedStore(false);
                }}
                selectedStore={selectedStore}
                dispatchData={dispatchData}
              />
            </CustomisedModal>
          )}
          {settingView && (
            <CustomisedModal
              modalView={settingView}
              customClass={
                "category-modal admin-support-ticket support-request-modal modal-lg"
              }
              modalName={"Table column configuration"}
              onModalDismiss={handleSettingsToggle}
            >
              <SettingTab
                dispatchData={dispatchData}
                settingView={settingView}
                setSettingView={setSettingView}
                data={colList?.columnsSelected?.length > 0 ? colList.columnsSelected : []}
                setColumesRequest={setColumesRequest}
                onModalDismiss={handleSettingsToggle}
              />
            </CustomisedModal>
          )}
        </div>
      </div>
    </>
  );
}

const mapStateToProps = ({ Members, SupportTicket }) => {
  let totalRecords = null;
  let allMemberList = [];
  let colList = [];
  if (Members?.getMembers?.list && Members?.getMembers?.list?.length > 0) {
    allMemberList = Members.getMembers.list;
    totalRecords = Members.getMembers.totalRecords;
  }
  if (SupportTicket.getColoumn?.list) {
    colList = SupportTicket.getColoumn.list;
  }

  return {
    allMemberList: allMemberList,
    totalRecords: totalRecords,
    colList: colList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    exportDataExportRequest: (data) => dispatch(exportDataExportRequest(data)),
    getMemberListReq: (data) => dispatch(getMemberListRequest(data)),
    removeStoreGroupRequest: (data) => dispatch(removeStoreGroupRequest(data)),
    setColumesRequest: (data) => dispatch(setColumesRequest(data)),
    getColumesRequest: (data) => dispatch(getColumesRequest(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MemberList);
