export const columns = [
  {
    label: "ACH Enrolled",
    field: "achEnrolled",
    className: "member-ach_enrolled",
  },
  {
    label: "Account Name",
    field: "accountName",
    className: "member-account_name",
  },
  {
    label: "BBG Status",
    field: "bbgStatus",
    className: "member-bbg_status",
  },
  {
    label: "Business Phone",
    field: "businessPhone",
    className: "member-business_phone",
  },

  {
    label: "Coke Bottler Region",
    field: "cokeBottlerRegion",
    className: "member-coke_bottler_region",
  },
  {
    label: "Contact Name",
    field: "contactName",
    className: "member-contact_name",
  },
  // {
  //   label: "Created By",
  //   field: "createdBy",
  //   className: "member-created_by"
  // },
  // {
  //   label: "Created Date",
  //   field: "createdDate",
  //   className: "member-created_date"
  // },
  {
    label: "Date Enrolled",
    field: "dateEnrolled",
    className: "member-date_enrolled",
  },
  {
    label: "EIN",
    field: "ein",
    className: "member-ein",
  },
  {
    label: "Email",
    field: "emailAddress",
    className: "member-email store-hra-mail",
  },
  {
    label: "Email Login",
    field: "emailLogin",
    className: "member-email_login",
  },
  {
    label: "Inactive Date",
    field: "inactiveDate",
    className: "member-inactive_date",
  },
  {
    label: "Inactive Reason",
    field: "inactiveReason",
    className: "member-inactive_reason",
  },
  {
    label: "Leads Requests",
    field: "leadsRequests",
    className: "member-leads_requests",
  },
  {
    label: "Mailing City",
    field: "mailingAddressCity",
    className: "member-mailing_city",
  },

  {
    label: "Mailing State",
    field: "mailingState",
    className: "member-mailing_state",
  },
  {
    label: "Mailing Street Address",
    field: "mailingStreetAddress",
    className: "member-mailing_street_address",
  },
  {
    label: "Mailing Zip",
    field: "mailingZip",
    className: "member-mailing_zip",
  },

  {
    label: "Master Dase",
    field: "masterDase",
    className: "member-master_dase",
  },
  {
    label: "Member ID Number",
    field: "memberIdNum",
    className: "member-id_number",
  },
  {
    label: "Old HRA",
    field: "oldHra",
    className: "member-old_hra",
  },
  {
    label: "Postal Code",
    field: "postalCode",
    className: "member-postal_code",
  },
  {
    label: "Phone No.",
    field: "phoneNumberMobileNumber",
    className: "member-phoneno",
  },

  {
    label: " Field Manager Territory",
    field: "managerTerritory",
    className: "member-representative",
  },
  {
    label: "RBNA Distributor",
    field: "rbnaDistributor",
    className: "member-rbna_distributor",
  },
  {
    label: "Relationship",
    field: "relationship",
    className: "relation_badge",
  },
  {
    label: "Secondary Email",
    field: "secondaryEmailAddress",
    className: "member-secondary_email",
  },

  {
    label: "Status",
    field: "status",
    className: "member-status",
  },
  {
    label: "Store Created Date",
    field: "storeCreatedDate",
    className: "member-store_created_date",
  },
  {
    label: "Store Name HRA",
    field: "storeNameHra",
    className: "member-store_name_hra",
  },

  {
    label: "Address",
    field: "streetAddress",
    className: "member-street_address",
  },
  {
    label: "City",
    field: "city",
    className: "member-city",
  },
  {
    label: "State",
    field: "state",
    className: "member-state",
  },
  {
    label: "County",
    field: "county",
    className: "member-county",
  },
  {
    label: "Territory",
    field: "territory",
    className: "member-territory",
  },
  {
    label: "Ticket Owner",
    field: "ticketOwner",
    className: "member-ticket_owner",
  },
  // {
  //   label: "Updated By",
  //   field: "updatedBy",
  //   className: "member-updated_by"
  // },
  // {
  //   label: "Updated Date",
  //   field: "updatedDate",
  //   className: "member-updated_date"
  // },
  {
    label: "User Name",
    field: "userName",
    className: "member-user_name",
  },
  {
    label: "W9",
    field: "w9",
    className: "member-w9",
  },
];

export const fixedCol = [
  {
    label: "HRA Id",
    field: "hraMemberId",
    className: "member-hra_id",
  },
  {
    label: "Store Name",
    field: "storeNameRegular",
    className: "member-store_name_regular",
  },
  {
    label: "Member",
    field: "firstName",
    className: "member-name",
  },

  // {
  //   label: "", // Empty label for the action button
  //   field: null,
  //   className: "member-action-btn"
  // },
];
