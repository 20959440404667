import React, { useState } from "react";
import CommonSortHeader from "../../../components/Common/Frontend/Promotion/CommonSortHeader";

import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  CardFooter,
  Col,
  FormGroup,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
} from "reactstrap";

import { connect } from "react-redux";
import {
  getStoreRebatesRequest,
  rebateRequest,
} from "../../../redux/frontend/rebate/action";
import { getValueByKey } from "../../../utils/authUtils";
import { useEffect } from "react";
import RebateChart from "./RebateChart";
import { ChartSvg, ListSvg } from "../../../components/CommonSvg";
import Select from "react-select";
import { getClassRequest } from "../../../redux/admin/rebate/action";
import ViewTable from "../../../components/Common/ViewTable";
import TableViewRebate from "./TableViewRebate";

function Rebate({
  rebateRequest,
  rebateDataRes,
  ClassList,
  zohoToken,
  rebateMessage,
  RebateData,
  getClassRequest,
  getRebateList,
  totalAmount,
}) {
  const hraMemberId = JSON.parse(getValueByKey("hraMemberId")) || "";
  const currentYear = new Date().getFullYear();
  const [RebateDataList, setRebateDataList] = useState([]);
  const [selectedYear, setSelectedYear] = useState(currentYear - 1);
  const [rebateData, setRebateData] = useState(null);
  const [selectedClass, setSelectedClass] = useState("");
  const [quarter, setQuarter] = useState("");
  const [chartView, setChartView] = useState(false);

  const handleChangeView = () => {
    setChartView(!chartView);
  };
  useEffect(() => {
    if (selectedClass) {
      setRebateDataList(RebateData);
    } else {
      setRebateDataList([]);
    }
  }, [RebateData]);
  const RebateDataToken = {
    zohoToken: getValueByKey("zohoToken") ? getValueByKey("zohoToken") : "",
    zohoTokenTime: getValueByKey("zohoTokenTime")
      ? getValueByKey("zohoTokenTime")
      : "",
    rebateYear: selectedYear ? selectedYear : "",
  };
  useEffect(() => {
    getClassRequest(RebateDataToken);
  }, [selectedYear]);

  // useEffect(() => {
  //     if (zohoToken) {
  //         setMarkerAndStartCountdown(zohoToken);
  //     }
  // }, [zohoToken]);

  // const Nodata =
  //     quarter === null ? "Please Select Quarter" : "No data available";
  const selectYear = [
    {
      value: currentYear,
      label: (
        <>
          Current Year <small>({currentYear.toString()})</small>
        </>
      ),
    },
    {
      value: currentYear - 1,
      label: (
        <>
          Previous Year <small>({(currentYear - 1).toString()})</small>
        </>
      ),
    },
  ];
  const selectQuarter = [
    {
      value: "",
      label: "All",
    },
    {
      value: "Q1",
      label: (
        <>
          <p>Quarter </p>1
        </>
      ),
    },
    {
      value: "Q2",
      label: (
        <>
          <p>Quarter </p>2
        </>
      ),
    },
    {
      value: "Q3",
      label: (
        <>
          <p>Quarter </p>3
        </>
      ),
    },
    {
      value: "Q4",
      label: (
        <>
          <p>Quarter </p>4
        </>
      ),
    },
  ];
  useEffect(() => {
    setRebateData(rebateDataRes);
  }, [rebateDataRes]);
  useEffect(() => {
    setQuarter("");
  }, [hraMemberId]);
  useEffect(() => {
    setRebateData(null);
    if (quarter === "") {
      rebateRequest({
        quarter: quarter,
        year: selectedYear,
        hraMemberId: hraMemberId,
        zohoToken: getValueByKey("zohoToken") ? getValueByKey("zohoToken") : "",
        zohoTokenTime: getValueByKey("zohoTokenTime")
          ? getValueByKey("zohoTokenTime")
          : "",
      });
    } else {
      setChartView(false);
      setRebateData(rebateDataRes);
    }
  }, [quarter, selectedYear, hraMemberId]);
  const dispatchData = {
    rebateClassId: selectedClass,
    hraMemberId: hraMemberId,
    zohoToken: getValueByKey("zohoToken") ? getValueByKey("zohoToken") : "",
    zohoTokenTime: getValueByKey("zohoTokenTime")
      ? getValueByKey("zohoTokenTime")
      : "",
    rebateQuarter: quarter,
    rebateYear: selectedYear,
  };

  useEffect(() => {
    if (selectedClass) {
      getRebateList(dispatchData);
    }
  }, [selectedClass, selectedYear, quarter === "", quarter]);
  useEffect(() => {
    setRebateDataList([]);
  }, [selectedClass === ""]);
  useEffect(() => {
    setRebateDataList([]);
  }, []);
  const childColums = [
    {
      dataField: "year",
      headerAttrs: { scope: "col", className: "year_column" },
      text: "Year",
      classes: "rebates_year",
      sort: true,
    },

    {
      dataField: "subClassName",
      headerAttrs: { scope: "col", className: "class_column" },
      text: "Class",
      classes: "rebates_name",
      sort: true,
    },
    {
      dataField: "volumeType",
      headerAttrs: { scope: "col" },
      text: "volume type",
      classes: "volume_type",
      sort: true,
    },
    {
      dataField: "cYVolume",
      headerAttrs: { scope: "col" },
      text: "Total CY-Volume",
      classes: "total_volume",
      sort: true,
    },
    {
      dataField: "Q1",
      text: "Q1",
      headerAttrs: { className: "q1_column" },
      classes: "text-end q1_rebate",
      sort: false,
      hidden: quarter === "Q1" || quarter === "" ? false : true,
      formatter: (cell, row) => {
        return (
          <>
            <div className="text-end">
              <span>$</span>
              {row.Q1}
              {/* <div>
                {row.checkNoQ1 && (
                  <>
                    <span> CK# : </span>
                    <b>{row.checkNoQ1}</b>
                  </>
                )}
              </div> */}
            </div>
          </>
        );
      },
    },
    {
      dataField: "Q2",
      text: "Q2",
      headerAttrs: { className: "q2_column" },
      classes: "text-end q2_rebate",
      sort: false,
      hidden: quarter === "Q2" || quarter === "" ? false : true,
      formatter: (cell, row) => {
        return (
          <>
            <div className="text-end">
              <span>$</span>
              {row.Q2}
              <div>
                {row.checkNoQ2 && (
                  <>
                    <span> CK# : </span>
                    <b>{row.checkNoQ2}</b>
                  </>
                )}
              </div>
            </div>
          </>
        );
      },
    },
    {
      dataField: "Q3",
      text: "Q3",
      headerAttrs: { className: "q3_column" },
      classes: "text-end q3_rebate",
      sort: false,
      hidden: quarter === "Q3" || quarter === "" ? false : true,
      formatter: (cell, row) => {
        return (
          <>
            <div className="text-end">
              <span>$</span>
              {row.Q3}
              <div>
                {row.checkNoQ3 && (
                  <>
                    <span> CK# : </span>
                    <b>{row.checkNoQ3}</b>
                  </>
                )}
              </div>
            </div>
          </>
        );
      },
    },
    {
      dataField: "Q4",
      text: "Q4",
      headerAttrs: { className: "q4_column" },
      classes: "text-end q4_rebate",
      sort: false,
      hidden: quarter === "Q4" || quarter === "" ? false : true,
      formatter: (cell, row) => {
        return (
          <>
            <div className="text-end">
              <span>$</span>
              {row.Q4}
              <div>
                {row.checkNoQ4 && (
                  <>
                    <span> CK# : </span>
                    <b>{row.checkNoQ4}</b>
                  </>
                )}
              </div>
            </div>
          </>
        );
      },
    },
    {
      dataField: "amount",
      text: "Total Amount",
      classes: "text-end total_amount",
      hidden: quarter === "" ? false : true,
      headerAttrs: { scope: "col" },
      sort: true,
      sortValue: (cell, row, sortOrder) => {
        const numericValue = parseFloat(row.totalAmount);
        return sortOrder === "asc" ? numericValue : -numericValue;
      },
      formatter: (cell, row) => {
        return (
          <>
            <div>
              <span>$</span>
              {row.amount}
            </div>
          </>
        );
      },
    },
  ];
  const columns = [
    {
      dataField: "year",
      headerAttrs: { scope: "col", className: "year_column" },
      text: "Year",
      classes: "rebates_year",
      sort: false,
    },

    {
      dataField: "rebateClass",
      headerAttrs: { scope: "col", className: "class_column" },
      text: "Class",
      classes: "rebates_name",
      sort: true,
    },
    {
      dataField: "volumeType",
      headerAttrs: { scope: "col" },
      text: "Volume type",
      classes: "volume_type",
      sort: false,
    },
    {
      dataField: "cYVolume",
      headerAttrs: { scope: "col" },
      text: "Total CY-Volume",
      classes: "total_volume",
      sort: false,
    },
    {
      dataField: "Q1",
      text: "Q1",
      headerAttrs: { className: "q1_column" },
      classes: "text-end q1_rebate",
      sort: false,
      hidden: quarter === "Q1" || quarter === "" ? false : true,
      formatter: (cell, row) => {
        return (
          <>
            <div className="text-end">
              <span>$</span>
              {row.Q1}
              <div>
                {row.checkNoQ1 && (
                  <>
                    <span> CK# : </span>
                    <b>{row.checkNoQ1}</b>
                  </>
                )}
              </div>
            </div>
          </>
        );
      },
    },
    {
      dataField: "Q2",
      text: "Q2",
      headerAttrs: { className: "q2_column" },
      classes: "text-end q2_rebate",
      sort: false,
      hidden: quarter === "Q2" || quarter === "" ? false : true,
      formatter: (cell, row) => {
        return (
          <>
            <div className="text-end">
              <span>$</span>
              {row.Q2}
              <div>
                {row.checkNoQ2 && (
                  <>
                    <span> CK# : </span>
                    <b>{row.checkNoQ2}</b>
                  </>
                )}
              </div>
            </div>
          </>
        );
      },
    },
    {
      dataField: "Q3",
      text: "Q3",
      headerAttrs: { className: "q3_column" },
      classes: "text-end q3_rebate",
      sort: false,
      hidden: quarter === "Q3" || quarter === "" ? false : true,
      formatter: (cell, row) => {
        return (
          <>
            <div className="text-end">
              <span>$</span>
              {row.Q3}
              <div>
                {row.checkNoQ3 && (
                  <>
                    <span> CK# : </span>
                    <b>{row.checkNoQ3}</b>
                  </>
                )}
              </div>
            </div>
          </>
        );
      },
    },
    {
      dataField: "Q4",
      text: "Q4",
      headerAttrs: { className: "q4_column" },
      classes: "text-end q4_rebate",
      sort: false,
      hidden: quarter === "Q4" || quarter === "" ? false : true,
      formatter: (cell, row) => {
        return (
          <>
            <div className="text-end">
              <span>$</span>
              {row.Q4}
              <div>
                {row.checkNoQ4 && (
                  <>
                    <span> CK# : </span>
                    <b>{row.checkNoQ4}</b>
                  </>
                )}
              </div>
            </div>
          </>
        );
      },
    },
    {
      dataField: "totalAmount",
      text: "Total Amount",
      classes: "text-end total_amount",
      hidden: quarter === "" ? false : true,
      headerAttrs: { scope: "col" },
      sort: true,
      sortValue: (cell, row, sortOrder) => {
        const numericValue = parseFloat(row.totalAmount);
        return sortOrder === "asc" ? numericValue : -numericValue;
      },
      formatter: (cell, row) => {
        return (
          <>
            <div>
              <span>$</span>
              {row.totalAmount}
            </div>
          </>
        );
      },
    },
  ];
  useEffect(() => {
    if (ClassList?.length > 0) {
      setSelectedClass(ClassList[0]?.value);
    }
  }, [ClassList?.length > 0]);

  return (
    <>
      {/* <h1 className="text-center mt-5 p-5 mb-5">Coming soon</h1> */}
      <div className="container rebate-wrapper">
        <CommonSortHeader
          PromotionsName="Rebates"
          selectYear={selectYear}
          selectedYear={selectedYear}
          setSelectedYear={setSelectedYear}
          BackTo={"/home"}
        />
        <div className="rebate_details mb-4">
          <Row>
            <Col className="d-flex justify-content-between align-items-end px-0">
              <Nav tabs className="promotion_tab request_tab">
                {selectQuarter.map((d) => {
                  return (
                    <NavItem>
                      <NavLink
                        className={quarter === d.value ? "active" : ""}
                        onClick={() => {
                          setQuarter(d.value);
                        }}
                      >
                        {d.label}
                      </NavLink>
                    </NavItem>
                  );
                })}
              </Nav>
            </Col>
          </Row>
          <TabContent
            className={
              chartView
                ? "promotion_tab-content border-0 row px-2 rebate_chart"
                : "promotion_tab-content border-0 row px-2"
            }
          >
            <div className="chart_btns">
              <div className="rebate-filter">
                <FormGroup>
                  <Select
                    menuPlacement="auto"
                    name="year"
                    className="form-control selectbox"
                    options={selectYear}
                    isClearable={false}
                    isMulti={false}
                    defaultValue={selectYear[0]}
                    value={
                      selectedYear
                        ? selectYear.find(
                            (option) => option.value === selectedYear
                          )
                        : null
                    }
                    onChange={(option) => {
                      setSelectedYear(option ? option.value : null);
                      if (!selectedClass) {
                        setSelectedClass();
                      }
                      if (!option) {
                        setSelectedClass();
                      }
                    }}
                  />

                  <Label className="form-label" htmlFor="year">
                    Year
                  </Label>
                </FormGroup>
                <FormGroup>
                  <Select
                    type="text"
                    id="class"
                    name="class"
                    className="form-control selectbox"
                    menuPlacement="auto"
                    options={ClassList}
                    // value={
                    //   ClassList?.length > 0 &&
                    //   ClassList?.filter(
                    //     (d) => d.value === selectedClass && d.label
                    //   )
                    // }
                    value={ClassList?.find((d) => d.value === selectedClass)}
                    onChange={(selectedOption) => {
                      if (selectedOption?.value) {
                        setSelectedClass(selectedOption.value);
                      } else {
                        setSelectedClass("");
                      }
                    }}
                    // isClearable
                  />
                  <Label className="form-label" htmlFor="class">
                    Class
                  </Label>
                </FormGroup>
              </div>
              {quarter === "" && (
                <ButtonGroup className="rebate_btn" onClick={handleChangeView}>
                  <Button className={chartView ? "active" : ""}>
                    <ChartSvg />
                    Chart
                  </Button>
                  <Button className={chartView ? "" : "active"}>
                    <ListSvg />
                    List
                  </Button>
                </ButtonGroup>
              )}
            </div>
            <div
              className={
                rebateData?.list?.length > 0 && chartView
                  ? "rebate_data rebate_bg"
                  : "rebate_bg"
              }
            >
              {rebateData?.list ? (
                rebateData?.list && (
                  <>
                    {rebateData?.list?.map((quarterData, index) => {
                      return (
                        <div>
                          <h6>{quarterData.storeId.name}</h6>
                          <div className="rebate_list">
                            {quarter !== "" &&
                              quarterData?.totalAmount
                                ?.filter((d) => d.Quarter === quarter)
                                ?.map((d) => (
                                  <Card>
                                    <CardFooter className="border-0 d-flex">
                                      <div className="d-flex justify-content-between w-100">
                                        <Badge
                                          color="primary"
                                          className="border-rounded me-3"
                                        >
                                          {d.Quarter}
                                        </Badge>
                                        <strong>$ {d.Amount}</strong>
                                      </div>
                                    </CardFooter>
                                  </Card>
                                ))}
                            {chartView && quarter === "" ? (
                              <RebateChart data={quarterData?.totalAmount} />
                            ) : (
                              quarter === "" && (
                                <>
                                  {quarterData?.totalAmount?.map((d) => (
                                    <Card>
                                      <CardFooter className="border-0 d-flex">
                                        <div className="d-flex justify-content-between w-100">
                                          <Badge
                                            color="primary"
                                            className="border-rounded me-3 lh-sm"
                                          >
                                            {d.Quarter}
                                          </Badge>
                                          <strong>$ {d.Amount}</strong>
                                        </div>
                                      </CardFooter>
                                    </Card>
                                  ))}
                                  <Card>
                                    <CardFooter className="border-0">
                                      <div className="d-flex justify-content-between">
                                        <Badge
                                          color="primary"
                                          className="border-rounded me-3 lh-sm"
                                        >
                                          Total
                                        </Badge>
                                        <strong>
                                          ${" "}
                                          {quarterData?.totalAmount
                                            ?.reduce(
                                              (total, data) =>
                                                total + parseFloat(data.Amount),
                                              0
                                            )
                                            .toFixed(2)}
                                        </strong>
                                      </div>
                                    </CardFooter>
                                  </Card>
                                </>
                              )
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </>
                )
              ) : (
                <h4 className="py-5 text-center">
                  {rebateMessage ? rebateMessage : "No Data Found"}
                </h4>
              )}
            </div>
          </TabContent>
        </div>
      </div>
      {RebateDataList?.length > 0 ? (
        RebateDataList.map((data) => {

          return (
            <div className="rebate-tables">
              <TableViewRebate
                data={data}
                columns={columns}
                selectedClass={selectedClass}
                quarter={quarter}
                totalAmount={data.QuarterWiseClassesTotal}
                setSelectedClass={setSelectedClass}
                childColums={childColums}
              />
              
            </div>
          );
        })
      ) : (
        <ViewTable
          responsiveClass="rebate_history"
          data={[]}
          columns={columns}
          selectedClass={selectedClass}
          setSelectedClass={setSelectedClass}
        />
      )}

      {!RebateDataList?.length > 0 && (
        <h4 className="text-center mt-5 mb-5">No Data Found</h4>
      )}
    </>
  );
}
const mapStateToProps = ({ rebate, rebateManagement }) => {
  let ClassList = [];
  let zohoToken = null;
  let RebateData = [];
  let totalAmount = null;

  if (
    rebate?.getStoreRebates?.data?.zohoToken &&
    rebate?.getStoreRebates?.data?.zohoTokenTime
  ) {
    localStorage.setItem("zohoToken", rebate?.getStoreRebates?.data?.zohoToken);
    localStorage.setItem(
      "zohoTokenTime",
      rebate?.getStoreRebates?.data?.zohoTokenTime
    );
  }
  if (
    rebateManagement.getClass?.zohoToken &&
    rebateManagement.getClass?.zohoTokenTime
  ) {
    localStorage.setItem("zohoToken", rebateManagement.getClass?.zohoToken);
    localStorage.setItem(
      "zohoTokenTime",
      rebateManagement.getClass?.zohoTokenTime
    );
  }
  if (rebate?.getStoreRebates?.data?.RebateData?.length > 0) {
    zohoToken = rebateManagement.getClass?.zohoToken
      ? rebateManagement.getClass?.zohoToken
      : null;

    RebateData = rebate.getStoreRebates?.data?.RebateData;
  }
  if (rebateManagement?.getClass?.getRebateClass?.length > 0) {
    ClassList = rebateManagement?.getClass?.getRebateClass?.map((data) => ({
      value: data.rebateClassId,
      label: data.rebateClassName,
    }));
  }
  if (rebate.getStoreRebates?.data?.totalsArray) {
    totalAmount = rebate.getStoreRebates?.data.totalsArray;
  }
  if (
    rebate?.rebateData?.data?.zohoToken &&
    rebate?.rebateData?.data?.zohoTokenTime
  ) {
    localStorage.setItem("zohoToken", rebate.rebateData.data.zohoToken);
    localStorage.setItem(
      "zohoTokenTime",
      rebateManagement.getClass.zohoTokenTime
    );
  }
  return {
    rebateDataRes: rebate?.rebateData?.data ? rebate.rebateData.data : null,
    rebateMessage: rebate?.rebateData?.message
      ? rebate.rebateData.message
      : null,
    ClassList: ClassList?.length > 0 ? [...ClassList] : [],
    zohoToken: zohoToken,
    RebateData: RebateData,
    totalAmount: totalAmount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    rebateRequest: (data) => dispatch(rebateRequest(data)),
    getClassRequest: (data) => dispatch(getClassRequest(data)),
    getRebateList: (data) => dispatch(getStoreRebatesRequest(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Rebate);
